const filters = {
  search: (items, searchTerm, searchFields) => {
    const matchingItems = [];
    for (const item of items) {
      for (const searchField of searchFields) {
        const parts = searchField.split(".");
        let path = item;
        for (const part of parts) {
          path = path[part];
        }
        if (path.toLowerCase().includes(searchTerm.toLowerCase())) {
          matchingItems.push(item);
          break;
        }
      }
    }
    return matchingItems;
  },
};

const filter = (items, searchTerm, searchFields) => {
  if (searchTerm.length) {
    return filters.search(items, searchTerm, searchFields);
  }
  return items;
};

export default filter;
