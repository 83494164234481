const sorters = {
  numberSort: (items, ascending, field) => {
    if (field) {
      return items.sort((a, b) =>
        ascending ? a[field] - b[field] : b[field] - a[field]
      );
    } else {
      return items.sort((a, b) => (ascending ? a - b : b - a));
    }
  },
  stringSort: (items, ascending, field) => {
    return items.sort(
      (a, b) =>
        ascending
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]),
      undefined,
      { sensitivity: "base" }
    );
  },
};

const sort = (items, ascending = true, field = 0) => {
  if (!items.length) return items;
  if (typeof items[0] == "number" || typeof items[0][field] == "number") {
    return sorters.numberSort(items, ascending, field);
  }
  return sorters.stringSort(items, ascending, field);
};

export default sort;
