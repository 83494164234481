<template>
  <div class="flex items-center justify-between">
    <i18n-t keypath="labels.pagination" scope="global" tag="p">
      <template v-slot:start>
        <span>{{ (modelValue - 1) * perPage + 1 }}</span>
      </template>
      <template v-slot:end>
        <span>{{
          modelValue * perPage > rows ? rows : modelValue * perPage
        }}</span>
      </template>
      <template v-slot:length>
        <span>{{ rows }}</span>
      </template>
    </i18n-t>
    <div class="flex overflow-hidden rounded-md border border-slate-300">
      <button
        class="focus:outline-none bg-whitefocus:ring flex h-8 w-8 cursor-pointer items-center justify-center rounded-l-md text-slate-700 focus:ring-inset disabled:cursor-default"
        :disabled="modelValue == 1"
        :style="{
          backgroundColor: hovers[0] ? company.primary[100] : null,
          '--tw-ring-color': company.primary[300],
        }"
        @click="emit('update:modelValue', --modelValue)"
        @mouseover="hovers[0] = true"
        @mouseleave="hovers[0] = false"
      >
        <ChevronLeftIcon class="h-4 w-4" />
      </button>
      <button
        v-for="(page, index) in pagesInRange"
        :key="page"
        class="focus:outline-none flex h-8 w-8 cursor-pointer items-center justify-center border-l border-slate-300 focus:ring focus:ring-inset"
        :class="{
          'text-white': company.primaryText == 'light' && page == modelValue,
          'text-slate-700': company.primaryText == 'dark' && page != modelValue,
        }"
        :style="{
          backgroundColor:
            page == modelValue
              ? hovers[index + 1]
                ? company.primary[600]
                : company.primary[500]
              : hovers[index + 1]
              ? company.primary[100]
              : 'white',
          color: company.primaryText,
          '--tw-ring-color': company.primary[300],
        }"
        @click="emit('update:modelValue', page)"
        @mouseover="hovers[index + 1] = true"
        @mouseleave="hovers[index + 1] = false"
      >
        {{ page }}
      </button>
      <button
        class="focus:outline-none flex h-8 w-8 cursor-pointer items-center justify-center rounded-r-md border-l text-slate-700 focus:ring focus:ring-inset disabled:cursor-default"
        :disabled="modelValue == pagesInRange.length"
        :style="{
          backgroundColor: hovers[pagesInRange.length + 1]
            ? company.primary[100]
            : 'white',
          '--tw-ring-color': company.primary[300],
        }"
        @click="emit('update:modelValue', ++modelValue)"
        @mouseover="hovers[pagesInRange.length + 1] = true"
        @mouseleave="hovers[pagesInRange.length + 1] = false"
      >
        <ChevronRightIcon class="h-4 w-4" />
      </button>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  components: { ChevronLeftIcon, ChevronRightIcon },
  props: {
    modelValue: {
      required: true,
      type: Number,
    },
    perPage: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();

    const company = computed(() => store.state.company.company);

    const hovers = ref([]);

    const pages = computed(() => {
      return Math.ceil(props.rows / props.perPage);
    });

    const pagesInRange = computed(() => {
      let start = props.modelValue - 2;
      start = start < 1 ? 1 : start;
      let end = start + 4;
      end = end > pages.value ? pages.value : end;
      start = start != end - 4 ? end - 4 : start;
      start = start < 1 ? 1 : start;

      let array = [];
      for (let i = start; i <= end; i++) {
        array.push(i);
      }
      return array;
    });

    watch(
      () => props.rows,
      () => {
        if (props.modelValue > pages.value) emit("update:modelValue", 1);
      }
    );

    return {
      company,
      emit,
      hovers,
      pages,
      pagesInRange,
      waiting: computed(() => store.state.company.waiting),
    };
  },
};
</script>
